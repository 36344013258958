import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Box, Flex, Stack, /*, Divider */ Spacer } from '@chakra-ui/react'
import {
	FaReceipt,
	/*  FaRegQuestionCircle, */ FaSignOutAlt,
	FaStore,
	FaUtensils,
	FaLaptopCode,
} from 'react-icons/fa'
import { Logo } from './Logo'
import { NavLink } from './NavLink'
import { useAuth } from '../../context/auth'

export const Sidebar = () => {
	const router = useRouter()

	const [currentPath, setCurrentPath] = useState('')

	const { logout } = useAuth()

	useEffect(() => {
		setCurrentPath(router.route)
	}, [router])

	const handleLogout = async () => {
		await logout()
		router.push('/login')
	}

	return (
		<Flex
			height="100vh"
			width="240px"
			direction="column"
			bg="teal"
			color="white"
			px={6}
			py={8}
			position="fixed"
			zIndex={10}
		>
			<Box mb={8}>
				<Logo />
			</Box>
			<Stack spacing={6}>
				<Stack>
					<NavLink
						label="注文管理"
						icon={FaReceipt}
						isActive={currentPath.match(/order_groups/) ? true : false}
						onClick={() => router.push('/order_groups')}
					/>
					<NavLink
						label="店舗管理"
						icon={FaStore}
						isActive={currentPath.match(/shops/) ? true : false}
						onClick={() => router.push('/shops')}
					/>
					<NavLink
						label="商品管理"
						icon={FaUtensils}
						isActive={currentPath.match(/products/) ? true : false}
						onClick={() => router.push('/products')}
					/>
					<NavLink
						label="パラメータ"
						icon={FaLaptopCode}
						isActive={currentPath.match(/system_parameters/) ? true : false}
						onClick={() => router.push('/system_parameters')}
					/>
				</Stack>
				{/* <Divider borderColor="whiteAlpha.400" />
				<Stack>
					<NavLink label="ヘルプ" icon={FaRegQuestionCircle} />
				</Stack> */}
			</Stack>
			<Spacer />
			<NavLink label="ログアウト" icon={FaSignOutAlt} onClick={handleLogout} />
		</Flex>
	)
}
