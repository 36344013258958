import React, { useEffect, useReducer } from 'react'
import { useRouter } from 'next/router'
import {
	Button,
	Center,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	Stack,
	Heading,
	Box,
} from '@chakra-ui/react'
import axios from '../../lib/axios-client'
import { firestore } from '../../lib/firebase-client'
import { useAuth } from '../auth'
import { useOrderGroup } from '../order-group'
import OrderGroupContext from './context'
import { reducer } from './reducer'
import { initialOrderGroupState } from './state'

export interface OrderGroupProviderOptions {
	children?: React.ReactNode
	[key: string]: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const OrderGroupProvider = (opts: OrderGroupProviderOptions) => {
	const { children } = opts

	const router = useRouter()

	const { isAuthenticated } = useAuth()
	const { listenNewOrders } = useOrderGroup()

	const [state, dispatch] = useReducer(reducer, initialOrderGroupState)

	const fetchOrderGroups = async (page?: number): Promise<void> => {
		dispatch({ type: 'FETCH_ORDERS_STARTED' })
		try {
			const res = await axios.get(
				`${process.env.REST_ENDPOINT}/super_admin/api/v1/order_groups?page=${page || 1}`
			)
			dispatch({
				type: 'FETCH_ORDERS_FINISHED',
				orderGroups: res.data.order_groups,
				totalPage: res.data.total_page,
			})
		} catch (error) {
			dispatch({ type: 'ERROR', error })
		}
	}

	const startListeningNewOrders = async () => {
		firestore.collection('orders').onSnapshot(() => {
			// TODO: ログインしている店舗の注文の場合のみ注文取得APIを叩く
			fetchOrderGroups()
		})
		dispatch({ type: 'SET_LISTEN_NEW_ORDERS' })
	}

	useEffect(() => {
		if (!isAuthenticated) {
			return
		}
		firestore.collection('orderGroups').onSnapshot(() => {
			fetchOrderGroups()
		})
	}, [isAuthenticated])

	useEffect(() => {
		setInterval(() => {
			fetchOrderGroups()
		}, 15 * 60 * 1000)
	}, [])

	return (
		<OrderGroupContext.Provider
			value={{
				...state,
				fetchOrderGroups,
			}}
		>
			{children}
			{/* {!listenNewOrders && router.route === '/order_groups' && (
				<Modal
					isOpen={isAuthenticated && !state.listenNewOrders}
					onClose={() => {
						console.log('')
					}}
					isCentered
				>
					<ModalOverlay />
					<ModalContent>
						<ModalBody p={8}>
							<Stack spacing={8}>
								<Center>
									<Heading size="lg">ナガタクへようこそ！</Heading>
								</Center>
								<Center>
									<Box>
										<Button colorScheme="teal" onClick={() => startListeningNewOrders()}>
											新規注文をチェック
										</Button>
									</Box>
								</Center>
							</Stack>
						</ModalBody>
					</ModalContent>
				</Modal>
			)} */}
		</OrderGroupContext.Provider>
	)
}

export default OrderGroupProvider
