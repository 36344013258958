import firebase from 'firebase'
import { Admin } from '../../types/admin'

export type FirebaseUser = firebase.User
export type FirebaseError = firebase.FirebaseError

/**
 * The auth state which, when combined with the auth methods, make up the return object of the `useAuth` hook.
 */
export interface AuthState {
	isLoading: boolean
	isModalOpen: boolean
	isAuthenticated: boolean
	currentFirebaseUser?: FirebaseUser
	currentAdmin?: Admin
	error?: FirebaseError
}

/**
 * The initial auth state.
 */
export const initialAuthState: AuthState = {
	isLoading: false,
	isModalOpen: false,
	isAuthenticated: false,
}
