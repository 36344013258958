import { OrderGroup } from '../../types/order_group'

/**
 * The auth state which, when combined with the auth methods, make up the return object of the `useOrderGroup` hook.
 */
export type OrderGroupState = {
	listenNewOrders: boolean
	isLoadingOrderGroups: boolean
	orderGroups?: OrderGroup[]
	totalPage?: number
	error?: Error
}

/**
 * The initial auth state.
 */
export const initialOrderGroupState: OrderGroupState = {
	listenNewOrders: false,
	isLoadingOrderGroups: false,
}
