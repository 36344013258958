import '../styles/globals.css'
import 'react-datepicker/dist/react-datepicker.css'
import React, { useEffect } from 'react'
import App from 'next/app'
import type { AppProps, AppContext } from 'next/app'
import { useRouter } from 'next/router'
import { ChakraProvider, Box, useToast } from '@chakra-ui/react'
import basicAuthCheck from '../utils/basicAuthCheck'
import { AuthProvider } from '../context/auth'
import { OrderGroupProvider } from '../context/order-group'
import { auth } from '../lib/firebase-client'
import { Sidebar } from '../components/Sidebar/Sidebar'

function MyApp({ Component, pageProps }: AppProps) {
	const router = useRouter()
	const showSidebar = router.pathname === '/login' ? false : true

	const toast = useToast()

	return (
		<AuthProvider auth={auth}>
			<ChakraProvider>
				<OrderGroupProvider>
					<Box>
						{showSidebar && <Sidebar />}
						<Box pl={showSidebar ? '240px' : 0}>
							<Box p={8}>
								<Component {...pageProps} />
							</Box>
						</Box>
					</Box>
				</OrderGroupProvider>
			</ChakraProvider>
		</AuthProvider>
	)
}

MyApp.getInitialProps = async (appContext: AppContext) => {
	const { req, res } = appContext.ctx
	if (req && res && process.env.ENABLE_BASIC_AUTH === 'true') {
		await basicAuthCheck(req, res)
	}

	const appProps = await App.getInitialProps(appContext)
	return { ...appProps }
}

export default MyApp
