import { createContext } from 'react'
import { OrderGroupState, initialOrderGroupState } from './state'

export interface OrderGroupContextInterface extends OrderGroupState {
	fetchOrderGroups: (page?: number) => Promise<void>
}

const stub = (): never => {
	throw new Error('You forgot to wrap your component in <OrderGroupProvider>.')
}

const initialContext = {
	...initialOrderGroupState,
	fetchOrderGroups: stub,
}

const OrderGroupContext = createContext<OrderGroupContextInterface>(initialContext)

export default OrderGroupContext
