import { OrderGroupState } from './state'
import { OrderGroup } from '../../types/order_group'

type Action =
	| { type: 'SET_LISTEN_NEW_ORDERS' }
	| { type: 'FETCH_ORDERS_STARTED' }
	| { type: 'FETCH_ORDERS_FINISHED'; orderGroups?: OrderGroup[]; totalPage: number }
	| { type: 'ERROR'; error?: any }

/**
 * Handles how that state changes in the `useOrderGroup` hook.
 */
export const reducer = (state: OrderGroupState, action: Action): OrderGroupState => {
	switch (action.type) {
		case 'SET_LISTEN_NEW_ORDERS':
			return {
				...state,
				listenNewOrders: true,
			}
		case 'FETCH_ORDERS_STARTED':
			return {
				...state,
				isLoadingOrderGroups: true,
				error: undefined,
			}
		case 'FETCH_ORDERS_FINISHED':
			return {
				...state,
				orderGroups: action.orderGroups,
				totalPage: action.totalPage,
				isLoadingOrderGroups: false,
				error: undefined,
			}
		case 'ERROR':
			return {
				...state,
				isLoadingOrderGroups: false,
				error: action.error,
			}
	}
}
