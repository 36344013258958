// import Head from 'next/head'
import Link from 'next/link'
// import Image from 'next/image'
import styles from '../styles/Home.module.css'

export default function Home() {
	return (
		<div className={styles.container}>
			{/* <Head>
				<title>Create Next App</title>
				<meta name="description" content="Generated by create next app" />
				<link rel="icon" href="/favicon.ico" />
			</Head> */}

			<main className={styles.main}>
				<h1 className={styles.title}>ナガタク管理画面へようこそ！</h1>

				<p className={styles.description}>
					全権限を持った管理者ユーザーとしてログインしています。アカウントの管理には十分注意してください。
				</p>

				<div className={styles.grid}>
					<Link href="/order_groups">
						<a className={styles.card}>
							<h2>注文管理 &rarr;</h2>
							<p>注文の新規作成や全店舗の注文の確認ができます。</p>
						</a>
					</Link>

					<Link href="/products">
						<a className={styles.card}>
							<h2>商品管理 &rarr;</h2>
							<p>全店舗の商品の新規作成、編集などの管理を行います。</p>
						</a>
					</Link>

					{/* <a href="https://github.com/vercel/next.js/tree/master/examples" className={styles.card}>
						<h2>Examples &rarr;</h2>
						<p>Discover and deploy boilerplate example Next.js projects.</p>
					</a>

					<a
						href="https://vercel.com/new?utm_source=create-next-app&utm_medium=default-template&utm_campaign=create-next-app"
						className={styles.card}
					>
						<h2>Deploy &rarr;</h2>
						<p>Instantly deploy your Next.js site to a public URL with Vercel.</p>
					</a> */}
				</div>
			</main>

			{/* <footer className={styles.footer}>
				<a
					href="https://vercel.com?utm_source=create-next-app&utm_medium=default-template&utm_campaign=create-next-app"
					target="_blank"
					rel="noopener noreferrer"
				>
					Powered by{' '}
					<span className={styles.logo}>
						<Image src="/vercel.svg" alt="Vercel Logo" width={72} height={16} />
					</span>
				</a>
			</footer> */}
		</div>
	)
}
