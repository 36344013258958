import { useContext } from 'react'
import OrderGroupContext, { OrderGroupContextInterface } from './context'

/**
 * ```js
 * const {
 *   // OrderGroup state:
 *   orders,
 * 	 totalPage,
 *   isLoading,
 *   error,
 *   // OrderGroup methods:
 *   fetchOrderGroups
 * } = useOrderGroup()
 * ```
 *
 * Use the `useOrderGroup` hook in your components to access the auth state and methods.
 */
const useOrderGroup = (): OrderGroupContextInterface => useContext(OrderGroupContext)

export default useOrderGroup
